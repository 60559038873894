import { useEffect, useLayoutEffect, useState } from 'react';
import { useModal } from "contexts/ModalProvider";
import axios from 'utils/axios'; 
import './ListPage.scss';
import Navbar from 'components/navbar/Navbar';
import { iTeachingUnit } from 'interfaces/teachingUnit';
import RecommendedUnit from 'components/recommended-unit/RecommendedUnit';
import PlusIcon from 'images/plus.svg?react';  
import EditIcon from 'images/edit-icon.svg?react';  
import RemoveIcon from 'images/remove-icon.svg?react';
import DownloadIcon from 'images/download.svg?react';  
import EditList from 'components/modals/EditList';
import AddList from 'components/modals/AddList';
import RemoveList from 'components/modals/RemoveList';
import { useParams } from 'react-router-dom';
import LoadingScreen from 'components/loading-screen/LoadingScreen';
import { useSnackbar } from 'contexts/SnackbarProvider';
import Footer from 'components/footer/Footer';
import { Helmet } from 'react-helmet-async';
import { websiteUrl } from 'utils/consts';

export interface List {
  id: number;
  name: string;
  count: number;
}

interface ListItem extends iTeachingUnit{
  id: number;
  name: string;
}

const ListPage = () => {
  const { id } = useParams();
  const [lists, setLists] = useState<List[]>([]);
  const [mainListId, setMainListId] = useState<number | null>(null);
  const [selectedList, setSelectedList] = useState<number | null>(null);
  const [listItems, setListItems] = useState<ListItem[]>([]);
  const [listsLoaded, setListsLoaded] = useState<boolean>(false);
  const { showModal } = useModal();
  const { openSuccessSnackbar } = useSnackbar();

  useEffect(() => {
    if (!id) return;
    setSelectedList(Number(id));
  }, [id]);

  useLayoutEffect(() => {
    if (!listsLoaded) return;
    if (id) {
      if (id === "0") {
        if (lists.length > 1) handleClickList(Number(lists[1]?.id));
        else if (mainListId) handleClickList(mainListId);
      } else {
        handleClickList(Number(id));
      }
    } else if (mainListId) handleClickList(mainListId);
  }, [listsLoaded])

  useLayoutEffect(() => {
    if (id) {
      if (isNaN(Number(id))) {
        window.history.replaceState(null, '', `/lists`);
        window.location.reload();
        return;
      }
      setSelectedList(Number(id));
    }
    refreshLists();
  }, []);

  const refreshLists = async (unselected?: boolean) => {
    const response = await axios.get('/api/lists');
    let allLists = await Promise.all(response.data.map(async (list: { id: number; is_main: boolean }) => {
      const listItemsRes = await axios.get(`/api/lists/${list.id}/list`);
      const itemsCount = listItemsRes.data.results.length; 
      if (list.is_main) {
        setMainListId(list.id);
        if (unselected) {
          setSelectedList(list.id);
          window.history.replaceState(null, '', `/lists`);
        }
      }
      return { ...list, count: itemsCount };
    }));
    allLists = allLists.sort((a, b) => a.id - b.id);
    setLists(allLists);
    setListsLoaded(true);
  };

  const removeUnitFromList = async (favorite_list_id: number, teaching_unit_id: any) => {
    await axios.delete(`/api/lists/${favorite_list_id}/teaching-units/${teaching_unit_id}`);
    handleClickList(favorite_list_id);
    openSuccessSnackbar("Výukový celek byl odebrán ze seznamu");
  };

  const handleClickList = async (listId: number) => {
    setSelectedList(listId);
    const response = await axios.get(`/api/lists/${listId}/list`);
    const newListItems = response.data.results;
    setListItems(newListItems);

    setLists(lists.map(list => list.id === listId ? {...list, count: newListItems.length} : list));
    if (listId === mainListId) window.history.replaceState(null, '', `/lists`);
    else window.history.replaceState(null, '', `/lists/${listId}`);
  };

  const downloadUnits = async () => {
    const response = await axios.get(`/api/lists/${selectedList}/download`, {responseType: 'blob'});
    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'edubklub_vyukove_celky.zip');
    link.click();
  }

  const handleAddListClick = () => {
    showModal(<AddList refreshLists={refreshLists} />);
  };

  const handleEditListClick = (list: List) => {
    showModal(<EditList list={list} refreshLists={refreshLists} />);
  };

  const handleRemoveListClick = (list: List) => {
    showModal(<RemoveList list={list} refreshLists={refreshLists} />);
  };
    
  return (
    <>
      <Helmet>
        <title>Eduklub - Seznam plánů</title>
        <meta name="description" content="Eduklub nabízí široký výběr výukových plánů pro aplikaci EDUBO i PDF export pro vlastní plánování výuky. Vyhledejte plány, nahrávejte, stahujte a hodnoťte."/>
        <link rel="canonical" href={`${websiteUrl}/lists${id && id !== "0" ? `/${id}` : ""}`} />
      </Helmet>
      <div className="list-page">
      <Navbar />
      {!listsLoaded ? <LoadingScreen/> :
        <div className="list-page-container">
          <div className="horizontal-menu scrollbar">
            <div className="menu-wrapper">
            {lists.map((list) => (
              <button 
                key={list.id} 
                className={`menu-item l-blue-bg font-16 ${selectedList === list.id ? 'active' : ''}`} 
                onClick={() => handleClickList(list.id)}
              >
                {list.name} ({list.count})
              </button>
            ))}
            </div>
            <button className="add-list-button p-purple-bg-h" onClick={handleAddListClick}>            
              <PlusIcon width="20px" height="20px"/>
            </button>
          </div>
          <div className="header-section">
            <h2 className='font-24-b'>{lists.find(list => list.id === selectedList)?.name || 'Neznámý seznam'}</h2>
            {mainListId !== selectedList ?
              <div className="header-buttons">
                <button className="edit-button l-green-bg-h" 
                  onClick={() => handleEditListClick(lists.find(list => list.id === selectedList) || {id: -1, name: '', count: 0})}>
                  <EditIcon width="20px" height="20px"/>
                </button>
                <button className="remove-button l-red-bg-h" 
                  onClick={() => handleRemoveListClick(lists.find(list => list.id === selectedList) || {id: -1, name: '', count: 0})}>
                  <RemoveIcon width="20px" height="20px"/>
                </button>
              </div>
            : null}
            </div>
            {selectedList !== null && listItems.length > 0 ? (
              <div className="grid-container">
                <div className="grid">
                  {listItems.map((item) => (
                    <RecommendedUnit key={item.id} {...item} isInListPage={true} removeUnitFromList={removeUnitFromList} selectedList={selectedList} />
                  ))}
                </div>
              </div>
            ) : (
              <p>V tomto listu nejsou žádné výukové celky.</p>
            )}
            <button className="download-button s-blue-bg-h" onClick={downloadUnits}>
              <DownloadIcon /> Stáhnout vše
            </button>
          </div>}
      </div>
      <Footer/>
    </>
  );
};

export default ListPage;