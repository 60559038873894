import Navbar from '../components/navbar/Navbar';
import './SearchPage.scss'
import SearchUnit from '../components/search-unit/SearchUnit';
import ExtendedFilter from '../components/extended-filter/ExtendedFilter';
import SearchBar from '../components/searchbar/Searchbar';
import { useEffect, useState } from 'react';
import axios from '../utils/axios';
import { iTeachingUnit } from '../interfaces/teachingUnit';
import LoadingScreen from '../components/loading-screen/LoadingScreen';
import JumpToTop from '../components/jump-to-top/JumpToTop';
import useWindowSize from '../utils/useWindowSize';
import useScrollPosition from '../utils/useScrollPosition';
import BreadcrumbNav from '../components/breadcrumb-nav/BreadcrumbNav';
import Categories from '../components/categories/Categories';
import Footer from 'components/footer/Footer';
import { getCachedCategories } from 'utils/getCachedCategories';
import { signal } from '@preact/signals-react';

import {
  filterQuery,
  activeFilters,
  activeTags
} from 'components/filter-logic/useFilterLogic';
import { Category } from 'interfaces/categories';
import { Helmet } from 'react-helmet-async';
import { websiteUrl } from 'utils/consts';

export const categories = signal<Category[]>([]);
export const categoriesFiltered = signal<Category[]>([]);
export const categoriesParamsLoaded = signal<boolean>(false);

const SearchPage = () => {
  const [sortBy, setSortBy] = useState<string>("");
  const [teachingUnitQuery, setTeachingUnitQuery] = useState<string>("");
  const [teachingUnits, setTeachingUnits] = useState<iTeachingUnit[]>([]);
  const [count, setCount] = useState<number>(0);
  const [nextPage, setNextPage] = useState<string>("");
  const [firstLoading, setFirstLoading] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [isJumpVisible, setIsJumpVisible] = useState<boolean>(false);
  const [searchContext, setSearchContext] = useState<string>("");
  const windowSize = useWindowSize();
  const scrollPosition = useScrollPosition();

  useEffect(() => {
    getCategories();
  }, [])

  useEffect(() => {
    if (windowSize[1] < scrollPosition) setIsJumpVisible(true);
    else setIsJumpVisible(false);
  }, [scrollPosition])

  useEffect(() => {
    if (teachingUnitQuery) {
      getNewTeachingUnits();
      window.history.replaceState(null, "Eduklub", teachingUnitQuery.slice(19, teachingUnitQuery[teachingUnitQuery.length - 1] === "&" ? -1 : undefined));
    }
  }, [teachingUnitQuery])

  useEffect(() => {
    if (!filterQuery.value) return;
    let baseTeachingUnitQuery: string = "/api/teaching-units?order-by=";
    if (sortBy) baseTeachingUnitQuery = baseTeachingUnitQuery + sortBy;
    else baseTeachingUnitQuery = baseTeachingUnitQuery + "-number_of_downloads"
    setTeachingUnitQuery(baseTeachingUnitQuery + filterQuery.value);
  }, [filterQuery.value, sortBy])

  const getNewTeachingUnits = async () => {
    let teachingUnitQueryFormated = teachingUnitQuery;
    if (teachingUnitQueryFormated.endsWith("&")) teachingUnitQueryFormated = teachingUnitQueryFormated.slice(0, -1);
    const newTeachingUnits = await axios.get(teachingUnitQueryFormated);
    setTeachingUnits(newTeachingUnits.data.results);
    setCount(newTeachingUnits.data.count);
    if (newTeachingUnits.data.next) setNextPage(newTeachingUnits.data.next);
    setFirstLoading(false);
  }

  const getMoreTeachingUnits = async () => {
    setLoading(true);
    const newTeachingUnits = await axios.get(nextPage);
    setTeachingUnits(prev => [...prev, ...newTeachingUnits.data.results]);
    setCount(newTeachingUnits.data.count);
    setNextPage(newTeachingUnits.data.next);
    setLoading(false);
  }
  
  const addTag = (newTag: string) => {
    activeTags.value = [...activeTags.value, newTag];
  };


  const getCategories = async () => {
    const newCategories = await getCachedCategories();
    categories.value = newCategories;
  };

  return (
    <>
      <Helmet>
        <title>Eduklub - Vyhledávání mezi plány výuky</title>
        <meta name="description" content="Najděte ideální plán výuky pomocí pokročilého vyhledávacího filtru na Eduklubu. Vyhledávejte mezi výukovými plány přesně podle svých potřeb."/>
        <link rel="canonical" href={`${websiteUrl}/search`} />
      </Helmet>
      <div className="searchpage">
        <Navbar />
        <BreadcrumbNav categoriesData={categories.value} />
        <div className="searchpage-container">
          <ExtendedFilter activeFilters={activeFilters}/>
          <div className="main-content">
            <div className='search-container'>
              <SearchBar activeFilters={activeFilters} addTag={addTag} button={false} setSearchContext={setSearchContext} searchContext={searchContext}/>
            </div>
            <Categories categoriesData={categories.value} setSortBy={setSortBy} />
            <section className="search-results-section">
              {teachingUnits.length === 0 &&
                <>
                  {firstLoading ? 
                    <LoadingScreen upper/>
                  :
                    <h2 className="no-results">Nenašli jsme žádný výukový celek, který odpovídá filtrům.</h2>
                  }
                </>
              }
              {teachingUnits.map((data, index) => {
                return (
                  <SearchUnit key={index} {...data}/>
                )
              })}
            </section>
            {count !== teachingUnits.length && 
              <>
                {!loading ?
                  <div className="load-more-container">
                    <button className="rounded-full font-16-b p-blue-bg-h text-white py-2" onClick={() => getMoreTeachingUnits()}>Načíst další</button>
                  </div>
                :
                  <LoadingScreen upper/>
                }
              </>
            }
          </div>
        </div>
        <JumpToTop dissapear={!isJumpVisible}/>
      </div>
      <Footer/>
    </>
  )
}
export default SearchPage;